import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermsContent from "../components/terms/terms-content"

//const desc = "Stay up to date with news from and about Archerhub, the premier digital freight marketplace with AI-enabled technology."
const Terms = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <TermsContent className="container mx-auto justify-center mt-10 lg:mt-32 mb-8" />
  </Layout>
)

export default Terms
